import { Component } from '@angular/core';
import { DataAbstract } from '../../../../core/classes/data.abstract';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrl: './text.component.scss'
})
export class TextComponent extends DataAbstract {

  public text!: string;

  public override dataInit(): void {
    this.text = this.data;
  }

}
