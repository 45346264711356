import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ISelectButtonDialog {
  title: string;
  content: string;
  items: string[];
  //btnYes: string;
  btnNo: string;
  isBtnDisabled: boolean;
  isDisableClose: boolean;
}

@Component({
  selector: 'app-select-button-dialog',
  templateUrl: './select-button-dialog.component.html',
  styleUrl: './select-button-dialog.component.scss'
})
export class SelectButtonDialogComponent {

  public title: string;
  public content: string;
  public items: string[];
  //public btnYes: string;
  public btnNo: string;
  public isBtnDisabled: boolean;

  constructor(
    public dialogRef: MatDialogRef<ISelectButtonDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ISelectButtonDialog
  ) {
    this.title = data.title;
    this.content = data.content;
    this.items = data.items;
    //this.btnYes = data.btnYes;
    this.btnNo = data.btnNo;
    this.isBtnDisabled = data.isBtnDisabled ?? false;
    this.dialogRef.disableClose = data.isDisableClose ?? false;
  }

  public onButtonClick(item: string) {
    this.dialogRef.close({
      close: true,
      data: item
    });
  }

  public onClickNo() {
    this.dialogRef.close({
      close: false
    });
  }

  public onClickYes() {
    this.dialogRef.close(true);
  }

}
