
import { FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { RoleUtility } from "../../util/role.utility";

export abstract class DialogCard<T>{
    title!: string;
    formGroup!: FormGroup;
    error!: string;
    roleUtility:RoleUtility = new RoleUtility();

    constructor(protected dialogRef: MatDialogRef<DialogCard<T>, any>){}

    abstract initForm():void;

    setTitle(data: any):void{
        this.title = data.title;
    }

    createErrorMessage(formGroup: FormGroup) {
        console.log(formGroup);
        
        for (const [key, value] of Object.entries(formGroup.controls)) {
            if (value.invalid) {
                return this.error = value.errors!['message'];
            }
        }
    }

    close(data?: T): void{
        this.dialogRef.close(data);
    }
    
}