import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trim'
})
export class TrimPipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    if (typeof value === 'string') {
      console.log('trim', value)
      return value.trim(); 
    } else {
      return ''; // value가 null 또는 undefined인 경우 빈 문자열 반환
    }
  }
}