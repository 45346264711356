<mat-toolbar>
  <!-- @if (globalService.currentUser.isSuperAdmin()) { -->
  <!-- @if (globalService.currentUser.userData.role.roleName === 'SuperAdmin') {
    <button mat-icon-button (click)="trigger(triggerType)" matTooltip="기본 메뉴">
      <mat-icon>menu</mat-icon>
    </button>
  } -->
  <img src="./assets/svg/space2.svg" width="40px" alt="로월드소프트 로고" (click)="onMenuClick(mainMenu)" class="pointer">
  <span style="margin-left: 10px; font-weight: bold;" class="gemini-gradient pointer" (click)="onMenuClick(mainMenu)">Loworld</span>
  
  <span class="example-spacer"></span>

  @if(currentUser.isLogin()) {
    <ul style="width: 100%; justify-content: center; gap: 40px" class="flex flex-v-center">
      @for (item of menuList; track $index) {
        @if(currentUser.userData.role.roleId >= item.minRole){
          <li>

            <a (click)="onMenuClick(item)" class="pointer" [ngClass]="{ 'select' : item.select }">{{item.name}}</a>
          </li>
        }
      }
    </ul>

    <div class="flex flex-v-center">
      <!-- <button mat-icon-button [matTooltip]="'계정관리'" (click)="onClickAccount()">
        <img src="./assets/images/ico-people.png"/>
      </button>
   -->
      <div class="flex flex-v-center pointer" style="gap: 10px; font-size: 14px" [matTooltip]="'계정관리'" (click)="onClickAccount()">
        <!-- <img src="./assets/images/ico-people.png"/> -->
        <img src="./assets/svg/light.svg" width="20px"> 
        <span class="color-green">{{ currentUser.userData.name }}</span>님 안녕하세요!
      </div>

      <button mat-icon-button (click)="logout()">
        <div class="flex flex-v-center">
          <mat-icon class="color-white">logout</mat-icon>
        </div>
      </button>

      <!-- <button mat-icon-button matTooltip="기능 리스트" [matMenuTriggerFor]="menu">
        <mat-icon class="color-white">apps</mat-icon>
      </button> -->

    </div>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="dynamicLayoutService.selectCategory()">
          <mat-icon>folder_open</mat-icon>
          <span>카테고리 선택</span>
        </button>
      <div style="margin: 10px; margin-left: 15px;">
        <mat-slide-toggle  [(ngModel)]="solutionService.isMasking"> 개인정보보안 {{ solutionService.isMasking ? 'ON' : 'OFF' }}</mat-slide-toggle>
      </div>
    </mat-menu>
  } @else {
    <button mat-button (click)="login()" style="margin-right: 20px; color: black;">
      <mat-icon class="color-white">login</mat-icon>
      <span style="color: rgb(48, 48, 48);">login</span>
    </button>
  }
</mat-toolbar>
