import { Component, Input, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StoreAbstract } from '../../../../core/classes/store.abstract';
import { UpdateAccountComponent } from '../../../../pages/membership/components/dialogs/update-account/update-account.component';
import { ConfirmDialogComponent } from '../../molecules/confirm-dialog/confirm-dialog.component';
import { MembershipService } from '../../../../pages/membership/membership.service';
import { User } from '../../../../pages/membership/interfaces/user.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  
})
export class HeaderComponent extends StoreAbstract implements OnInit{

  @Input() triggerType!: string;

  public menuList = [
    { name : 'Factory', select : false, url : '/slot', minRole: 100},
    { name : 'Category', select : false, url : '/category-manage', minRole: 100},
    { name : 'User', select : false, url : '/membership/user', minRole: 200},
    { name : 'Admin', select : false, url : '/membership/admin', minRole: 200},
  ];

  public mainMenu = { name : 'Main', select : false, url : '/main', minRole: 100}

  private currentMenu = this.menuList.first();
  private readonly router = inject(Router);
  private readonly dialog = inject(MatDialog);

  ngOnInit(): void {
    const subscription = this.router.events.subscribe((val) => {
      if (val && val.constructor.name === 'NavigationEnd') {
        const currentUrl = this.router.url;
        this.menuList.forEach(d =>{
          if(d.url === currentUrl){
            d.select = true;
            this.currentMenu = d;
          }
        })
        subscription.unsubscribe();
      }
    });
  }


  onMenuClick(item:any) {
    this.currentMenu.select = false;
    item.select = true;
    this.currentMenu = item;
    this.router.navigateByUrl(item.url);
  }

  logout() {
    this.globalService.currentUser.removeUser();
    this.membershipService.user.next({} as User);
    this.currentUser.removeUser();
    this.router.navigate(['/membership/login']);
  }

  login() {
    this.router.navigate(['/membership/login']);
  }

  onClickAccount() {
    const dialog = this.dialog.open(UpdateAccountComponent, {
      width: '80%',
      minWidth: '500px',
      maxWidth: '600px',
      data: {
        title: '계정 관리',
        user: this.currentUser.userData
      }
    });
  }

}
