import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '../../../interfaces/user.interface';
import { requiredForm } from '../../../functions/formcontrol/required.validator';
import { DialogCard } from '../../abstracts/dialog-card.abstract';
import { MembershipService } from '../../../membership.service';
import { IBaseResponse } from '../../../../category-manage/interfaces/iBaseResponse.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidatePasswordDto } from '../../../dto/validate-password.dto';

@Component({
  selector: 'app-validate-password',
  templateUrl: './validate-password.component.html',
  styleUrl: './validate-password.component.scss'
})
export class ValidatePasswordComponent extends DialogCard<ValidatePasswordComponent> implements OnInit {

  @Output() isValid = new EventEmitter<void>();

  hide = true;
  password = new FormControl('', requiredForm('password'));
  user!:User;

  constructor(
    dialogRef: MatDialogRef<ValidatePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private membershipService: MembershipService
  ){
    super(dialogRef)
  }

  ngOnInit(): void {
    this.user = this.data.user;
    this.setTitle(this.data);
  }

  validatePassword(){
    const param = new ValidatePasswordDto();
    param.userIdx = this.user.userIdx!;
    param.password = this.password.value!;
    this.membershipService.validatePassword<IBaseResponse<boolean>>(param).subscribe({
      next: result => {
        console.log('결과',result);
          this.isValid.next();
          this.close();
      },
      error: (e: HttpErrorResponse) => {
        console.log('실패 결과', e);
        this.error = e.error.data.message;
      }
    })
  }

  override initForm(): void {
  }
}
