import { Component, Input, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { IMenu, IMenuData } from './menu.interface';

// export interface IMenu {
//   itemName: string;
//   itemFunc(data? : any): void;
//   checkDisabled(data?:any): boolean;
// }

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent implements IMenu{
  @ViewChild(MatMenuTrigger) menu!: MatMenuTrigger;
  @Input() neededData!: any;
  @Input() menuData: Array<IMenuData> = [];

  openMenu(){
    console.log('neededData', this.neededData);
    this.menu.openMenu();
  }
  
}
