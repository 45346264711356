<mat-card class="example-card">
    <app-card-header [title]="title" (closeEvent)="close()"></app-card-header>
    <mat-divider class="divider"></mat-divider>
    <mat-card-content align="center">
        <div class="content-box">

            <mat-form-field class="custom-form-field">
                <mat-label>password</mat-label>
                <input matInput [formControl]='password' matTooltip="비밀번호" [type]="hide ? 'password' : 'text'" (keydown.enter)="validatePassword()">
                <button mat-icon-button type="button" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
            
            <button class="custom-button" mat-raised-button color="primary" (click)="validatePassword()">확인</button>
                
            @if (error) {
            <div class="error-message">
                <span>{{error}}</span>
            </div>
            }

        </div>

    </mat-card-content>
</mat-card>
