import { Directive, ElementRef, HostListener } from '@angular/core';
import { StoreAbstract } from '../../core/classes/store.abstract';
import { inject } from '@angular/core';

@Directive({
  selector: '[appSplitter]'
})
export class SplitterDirective extends StoreAbstract{

  private boundDrag = this.drag.bind(this);
  private boundDragEnd = this.dragEnd.bind(this);

  private readonly el = inject(ElementRef);

  @HostListener('mouseenter', ['$event'])
  mouseEnter(event: MouseEvent) {
    this.hover('2px solid #227b4f');
  }

  @HostListener('mouseleave', ['$event'])
  mouseLeave(event: MouseEvent) {
    this.hover('2px solid #cdcdcd');
  }
  
  private hover(color: string) {
    this.el.nativeElement.style.borderLeft = color;
  }

  @HostListener('mousedown', ['$event'])
  public dragStart(event: MouseEvent) {
    event.preventDefault();
    document.addEventListener('mousemove', this.boundDrag);
    document.addEventListener('mouseup', this.boundDragEnd);
  }

  public drag(event: MouseEvent) {
    const maxLeftWidth = 50;
    const containerRect = this.el.nativeElement.parentElement.getBoundingClientRect();
    const x = event.clientX - containerRect.left;

    document.body.style.cursor = 'ew-resize';
    
    let leftWidth =  (x / containerRect.width) * 100;
    leftWidth = Math.max(0, Math.min(maxLeftWidth, leftWidth));
    const rightWidth = 100 - leftWidth;

    this.storeService.resize({
      leftWidth,
      rightWidth
    }); 
  }

  public dragEnd(event: MouseEvent) {
    document.removeEventListener('mousemove', this.boundDrag);
    document.removeEventListener('mouseup', this.boundDragEnd);
  
    document.body.style.cursor = '';
    
    this.resize$.subscribe(d => {
      if (d.leftWidth === 0) {
        this.layoutService.isOpen = false;
      }
      else if (d.leftWidth <= 50) {
        this.layoutService.isOpen = true;
      }
    });
  }

}
