import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { User } from '../../../interfaces/user.interface';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl } from '@angular/forms';
import { passwordValidator } from '../../../functions/formcontrol/password.validator';
import { passwordMatchValidate } from '../../../functions/formcontrol/password-match.validator';
import { ValidatePasswordComponent } from '../validate-password/validate-password.component';
import { UpdateUserDto } from '../../../dto/update-user.dto';
import { requiredForm } from '../../../functions/formcontrol/required.validator';
import { email } from '../../../functions/formcontrol/email.validator';
import { DialogCard } from '../../abstracts/dialog-card.abstract';
import { IBaseResponse } from '../../../../category-manage/interfaces/iBaseResponse.dto';
import { GlobalService } from '../../../../../core/services/global.service';

@Component({
  selector: 'app-update-account',
  templateUrl: './update-account.component.html',
  styleUrl: './update-account.component.scss'
})
export class UpdateAccountComponent extends DialogCard<UpdateAccountComponent> implements OnInit {
  user!: User;
  isValid: boolean = false;
  hide = true;

  // 템플릿 참조 변수를 사용하여 input 엘리먼트에 접근
  @ViewChild('confirmPasswordInput', {static: true}) confirmPasswordInput!: ElementRef;
  isControlsAppended = false;
  

  constructor(
    dialogRef: MatDialogRef<UpdateAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private globalService: GlobalService
  ){
    super(dialogRef)
  }


  ngOnInit(): void {
    // this.user = dummy.userArray[0];
    this.user = this.data.user
    this.initForm();
    this.setTitle(this.data);
  }
  

  override initForm(){

    this.formGroup = this.formBuilder.group({
      department: [this.user.department],
      email: [this.user.email, [requiredForm('이메일'), email()]],
      phone: [this.user.phone, ],
      password: ['', []],
      confirmPassword: ['', []]
    })
  }

  validatePassword() {
    const dialog = this.dialog.open(ValidatePasswordComponent, {
      width: '40%',
      minWidth: '350px',
      maxWidth: '500px',
      data: {
        title: '비밀번호',
        user: this.user
      },
    })

    dialog.componentInstance.isValid.subscribe(() => {
      this.isValid = true;
      this.addValidator();
    })
  }

  appendContorls(){
    console.log('전 ', this.confirmPasswordInput);
    
    this.formGroup.addControl('password', new FormControl('', []));
    this.formGroup.addControl('confirmPassword', new FormControl('', [passwordMatchValidate()]));
  }

  addValidator(){
    this.formGroup.controls['password'].setValidators([requiredForm('비밀번호'),passwordValidator(this.confirmPasswordInput)]);
    this.formGroup.controls['password'].updateValueAndValidity();
    this.formGroup.controls['confirmPassword'].setValidators([passwordMatchValidate()]);
    this.formGroup.controls['confirmPassword'].updateValueAndValidity();
  }

  update(){
    if(!this.formGroup.valid){
      this.createErrorMessage(this.formGroup);
      return;
    }
    const updateParam = this.createParam();
    console.log('업데이트',updateParam);
    this.globalService.membershipService.updateUser<IBaseResponse<User>>(updateParam).subscribe({
      next: result => {
        this.success(result.data);
      },
      error: e => {
        console.log(e);
        
      }
    });
  }

  success(user:User) {
    this.globalService.currentUser.setUser(user);
    this.close();
  }


  createParam(){
    const group = this.formGroup;
    const param = new UpdateUserDto();
    param.userIdx = this.user.userIdx!;
    param.email = group.get('email')?.value;
    param.password = group.get('password')?.value;
    param.department = group.get('department')?.value;
    param.phone = group.get('phone')?.value;
    return param;
  }

  logout(){
    this.globalService.currentUser.removeUser();
    this.close();
  }
  
}
