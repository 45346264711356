import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogCard } from '../../abstracts/dialog-card.abstract';

@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.component.html',
  styleUrl: './card-header.component.scss'
})
export class CardHeaderComponent {
  @Input() title!: string;
  @Output() closeEvent = new EventEmitter<void>();

  close(){
    this.closeEvent.next();
  }
}
