
    <mat-card class="example-card">
        <app-card-header [title]="title" (closeEvent)="close()"></app-card-header>
        <mat-divider class="divider"></mat-divider>
        <mat-card-content align="center">
            <div [formGroup]="formGroup" class="form-filed">
                <div class="inner-item">
                    <div class="item-left">
                        아이디
                    </div>
                    <div class="item-right text-row-right align-side">
                        {{user.id}}
                    </div>
                </div>
                <div class="inner-item">
                    <div class="item-left">
                        이름
                    </div>
                    <div class="item-right text-row-right">
                        {{user.name}}
                    </div>
                </div>
                <div class="inner-form">
                    <div class="item-left form-row">
                        소속
                    </div>
                    <div class="item-right">
                        <mat-form-field appearance="fill" class="custom-form-field">
                            <mat-label>department</mat-label>
                            <input matInput formControlName="department" placeholder="소속을 입력하세요" matTooltip="소속">
                        </mat-form-field>
                    </div>
                </div>
                <div class="inner-form">
                    <div class="item-left form-row">
                        이메일
                    </div>
                    <div class="item-right">
                        <mat-form-field appearance="fill" class="custom-form-field">
                            <mat-label>email</mat-label>
                            <input matInput formControlName="email" placeholder="이메일을 입력하세요" matTooltip="이메일">
                        </mat-form-field>
                    </div>
                </div>
                <div class="inner-form">
                    <div class="item-left form-row">
                        전화번호
                    </div>
                    <div class="item-right">
                        <mat-form-field appearance="fill" class="custom-form-field">
                            <mat-label>phone</mat-label>
                            <input matInput formControlName="phone" placeholder="전화번호를 입력하세요" matTooltip="전화번호">
                        </mat-form-field>
                    </div>
                </div>

                <div [style.display]="isValid ? 'block' : 'none'">
                    <!-- <div class="inner-item">
                        <div class="item-left">
                            현재 비밀번호
                        </div>
                        <div>
                            현재 비밀번호
                        </div>
                    </div> -->
                    <div class="inner-form">
                        <div class="item-left form-row">
                            새 비밀번호
                        </div>
                        <div class="item-right">
                            <mat-form-field class="custom-form-field">
                                <mat-label>password</mat-label>
                                <input matInput formControlName="password" matTooltip="새비밀번호" [type]="hide ? 'password' : 'text'">
                                <button mat-icon-button type="button" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="inner-form">
                        <div class="item-left form-row">
                            새 비밀번호 확인
                        </div>
                        <div class="item-right">
                            <mat-form-field class="custom-form-field">
                                <mat-label>confirm password</mat-label>
                                <input matInput #confirmPasswordInput formControlName="confirmPassword" matTooltip="새비밀번호" [type]="hide ? 'password' : 'text'">
                                <button mat-icon-button type="button" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="alert-text">※ 비밀번호는 8~16자 영문, 숫자, 특수문자를 포함해야 합니다.</div>
                </div>
                
                @if(!isValid){
                    <div class="inner-item">
                        <div class="item-left button-row">
                    
                        </div>
                        <div class="item-right">
                            <button mat-raised-button color="primary" (click)="validatePassword()">비밀번호 변경</button>
                        </div>
                    </div>
                }
                
                @if(error){
                    <div class="inner-form">
                        <div class="item-left button-row">
                        
                        </div>
                        <div class="item-right error-message">
                            <span>{{error}}</span>
                        </div>
                        
                    </div>
                }
            </div>

        </mat-card-content>
        <mat-card-footer align="center">
            <div class="container-bottom">
                <button mat-raised-button color="primary" (click)="update()">저장</button>
                <span class="spacer"></span>
                <button mat-raised-button  (click)="close()">취소</button>
            </div>
        </mat-card-footer>
    </mat-card>
