import { RoleId } from "./enum/role-id.enum";
import { RoleName } from "./enum/role-name.enum";
import { Role } from "../interfaces/role.interface";

export class RoleUtility{
    private _roleMapByRoleId!:Map<number, Role>;
    private _roleMapByRoleName!: Map<string, Role>;

    constructor(){
        this.initMap();
    }

    private initMap(){
        this.initIdMap();
        this.initNameMap();
    }

    private initIdMap(){
        this._roleMapByRoleId = new Map<number, Role>([
            [RoleId.SuperAdmin, { roleId: RoleId.SuperAdmin, roleName: RoleName.SuperAdmin }],
            [RoleId.Admin, { roleId: RoleId.Admin, roleName: RoleName.Admin }],
            [RoleId.User, { roleId: RoleId.User, roleName: RoleName.User }]
        ]);
    }

    private initNameMap(){
        this._roleMapByRoleName = new Map<string, Role>([
            [RoleName.SuperAdmin, { roleId: RoleId.SuperAdmin, roleName: RoleName.SuperAdmin }],
            [RoleName.Admin, { roleId: RoleId.Admin, roleName: RoleName.Admin }],
            [RoleName.User, { roleId: RoleId.User, roleName: RoleName.User }]
        ]);
    }

    get roleMapByRoleId(): Map<number, Role> {
        return this._roleMapByRoleId;
    }

    get roleMapByRoleName(): Map<string, Role>{
        return this._roleMapByRoleName;
    }

    
}