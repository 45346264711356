import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeviewComponent } from './treeview/treeview.component';
import { TreerowComponent } from './treerow/treerow/treerow.component';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { MenuItemModule } from '../../components/menu-item/menu-item.module';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    TreeviewComponent,
    TreerowComponent
  ],
  imports: [
    CommonModule,
    MenuItemModule,
    //material
    CdkAccordionModule,
    MatButtonModule,
    MatIconModule,
    DragDropModule,
    MatMenuModule

  ],
  exports: [
    TreeviewComponent,
    MenuItemModule,
  ]
})
export class TreeviewModule { }
