import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TreeviewStore } from '../../tree.store';
import { ITreeDatasource } from '../../tree.interface';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MenuComponent } from '../../../../components/menu-item/menu/menu.component';
import { GlobalService } from '../../../../core/services/global.service';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { CheckService } from '../../service/check.service';
import { TreeService } from '../../service/tree.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-treerow',
  templateUrl: './treerow.component.html',
  styleUrl: './treerow.component.scss'
})
export class TreerowComponent<D extends ITreeDatasource> implements OnInit, OnChanges {
  //딱 내가 갖은 데이터만 갖게 하자!(근데 id도 나중에 필요 없을수도..?)
  @Input() id!: number;
  @ViewChild('menu', { static: false }) menuComponent!: MenuComponent;

  isAccordionOpened: boolean = true;
  isChecked: boolean = false;
  isClicked: boolean = false;//현재 클릭된 트리 확인
  data!: D;  //datasource에서 내 값에 해당하는 메모리를 찾아와서 담고 있음
  constructor(
    private readonly treeviewStore: TreeviewStore,
    public readonly globalService: GlobalService,
    private readonly checkService: CheckService,
    public readonly treeService: TreeService<D>
  ){}

  ngOnInit(): void {
    this.treeviewStore.clickedTreeId$.subscribe(d =>{
      if(d.id === this.id){
        this.isClicked = true;
        //console.log('d',d, this.id, this.isClicked);
        return;
      }
      this.isClicked = false;
      
    });
  }

  //트리거가 안됨!
  ngOnChanges(changes: SimpleChanges): void{
    //id값이 변하면 맞는 데이터를 찾아오게
    this.data = this.treeService.findMe(this.id)!;

    if(this.data.id === 1) {
      timer(100).subscribe(d => {
        this.clickLeft();
      })
    }

    /**
     * 나의 id가 checked와 같으면 isChecked를 true로 설정하자!
     */
    const isMe = this.checkService.checked.find(d => d === this.data.id);
    if(!isMe) return;
    this.isChecked = true;
  }  
  /*
  나는 dragdrop 하면 컴포넌트 자체가 움직이는 줄 알았는데 실제로는 안에 데이터만 바뀌는거다.
  */

  dragdrop(event: CdkDragDrop<any[]>) {
    console.log('drop event', this.data.id, event);//event: 이벤트가 발생해서 바뀐 인덱스
    //this.data.children
    moveItemInArray(this.data.children, event.previousIndex, event.currentIndex);//일단 바꿔놓고
    this.treeviewStore.treeMove({
      movedParentId: this.data.id,
      movedData: {
        previousIndex: event.currentIndex,//잘못되면 되돌릴 수 있게
        currentIndex: event.previousIndex,
      }
    });

    /**
     * 방법1: 바뀌는 previousIndex, currentIndex를 부모로 보내서 성공 하면 그제서야 바꾼다.(이게 보내는 데이터 양이 더 적다!)
     * 방법2: 미리 화면 바꿔놓고 바꾸기 전 순서도 같이 보내준다.
     */
  } 

  clickLeft() {


    this.globalService.currentCategoryService.select(this.data, this.treeService);
    this.globalService.slotFormService.select(this.data, this.treeService);

    this.treeviewStore.treeClick(this.id);
    if(this.treeService.checkDisabled) return;
    this.isChecked = !this.isChecked;
    this.treeviewStore.treeCheck({ clickedTreeId: this.id,
      isChecked: this.isChecked });
  }

  clickRight(event: Event){
    event.preventDefault();
    event.stopPropagation();
    if(!this.menuComponent) return;
    this.menuComponent.openMenu();
    this.menuComponent.menu.menuClosed.subscribe(d=>{

    });
  }

  checkLeaf(){
    return this.data.children.length > 0 ? false : true
  }

  toggle(event: Event){
    this.isAccordionOpened = !this.isAccordionOpened;
    event.stopPropagation();
  }
}
